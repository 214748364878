import theme from "../theme/theme";

export function isPowerUser(permission) {
    return permission?.UPDATE;
}

export function isNormalUserEditing(entry) {
    return !isPowerUser(entry?.permission) && entry?.status !== "IN_REVIEW" && entry?.status !== "APPROVED";
}

export function isEditableCell(collectorPermission, collectorEntry, date, cutoffDate, block) {
    return collectorPermission === "READ_WRITE" && (isPowerUser(collectorEntry.permission) || (collectorEntry.permission?.UPDATE_FUTURE
        && (collectorEntry.status === "PENDING" || collectorEntry.status === "IN_PROGRESS") && isForecastDate(date, cutoffDate) && block === "ACTUAL"));
}

export function isActualDate(date, cutoffDate) {
    return date < cutoffDate;
}

export function isForecastDate(date, cutoffDate) {
    return date >= cutoffDate;
}

export function getCellColor(isEditable, block, date, cutoffDate) {
    if (!isEditable || block !== "ACTUAL") {
        return "transparent";
    } else if (isActualDate(date, cutoffDate)) {
        return theme.palette.collector.actualCell;
    } else if (isForecastDate(date, cutoffDate)) {
        return theme.palette.collector.forecastCell;
    }
}

export function getFontColor(isEditable, value) {
    if (value < 0) {
        return "red";
    } else if (!isEditable) {
        return "grey";
    } else {
        return "black";
    }
}
