import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import ClickableDatePicker from "../../components/ClickableDatePicker";
import { isForecastingEnabled } from "../../util/feature_flag_util";
import { isEmpty, omit, union, without } from "lodash";
import DimensionSelector from "../../components/selector/DimensionSelector";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import SelectField from "../../components/field/SelectField";
import { AppContext } from "../../AppRouter";

const ReportCustomizationBar = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    globalMin,
    globalMax,
    maxDate,
    setMaxDate,
    scenario,
    setScenario,
    scenarioOptions,
    multiplier,
    setMultiplier,
    multiplierOptions,
    hideRows,
    setHideRows,
    hideInitialMonths,
    setHideInitialMonths,
    documentDimensions,
    UIFiltersInDimensions,
    UIFiltersInParameters,
    UIFiltersOutDimensions,
    UIFiltersOutParameters,
    reportDimensionsMetadata,
    onFiltersInUpdate,
    onFiltersOutUpdate,
    cutoffDate,
    setCutoffDate,
    disabled,
}) => {
    const { config } = useContext(AppContext);

    return (
        <Box
            datacy="customization_bar"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                px: 2,
                pt: 2,
                pb: 1,
                overflow: "auto",
            }}
        >
            <Grid container spacing={2} rowSpacing={2}>
                <Grid item xs={6}>
                    <ClickableDatePicker
                        dataCyProp="startDate_selector"
                        disabled={disabled}
                        required
                        views={["year", "month"]}
                        format="MMM YYYY"
                        label={config.i18n.procurement.project.start_date_column}
                        value={startDate || null}
                        minDate={globalMin}
                        maxDate={globalMax < endDate ? globalMax : endDate}
                        onAccept={newStartDate => setStartDate(newStartDate.startOf("month").startOf("date"))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ClickableDatePicker
                        dataCyProp="endDate_selector"
                        disabled={disabled}
                        views={["year", "month"]}
                        format="MMM YYYY"
                        label={config.i18n.procurement.project.end_date_column}
                        value={endDate || null}
                        minDate={startDate || globalMin || null}
                        maxDate={maxDate}
                        onAccept={newEndDate => setEndDate(newEndDate.endOf("month").endOf("date"))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        label={config.i18n.selector.scenario}
                        freeSolo
                        forcePopupIcon
                        value={scenario || ""}
                        options={scenarioOptions || []}
                        getOptionLabel={option => option?.name || ""}
                        disabled={!isForecastingEnabled(config) || isEmpty(scenarioOptions) || disabled}
                        size="small"
                        onChange={(_, value) => {
                            setScenario(value || {});

                            if (!value) {
                                setEndDate(endDate > globalMax ? globalMax : endDate);
                                setMaxDate(globalMax);
                            } else {
                                setMaxDate(config.time.forecasting.max);
                            }
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={config.i18n.selector.scenario}
                                inputProps={{ ...params.inputProps, "readOnly": true, "data-cy": "scenario_autocomplete" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ClickableDatePicker
                        dataCyProp="cutoff_date_selector"
                        label={config.i18n.selector.cutoff_date}
                        views={["year", "month"]}
                        format="MMM YYYY"
                        disabled={!isForecastingEnabled(config) || !scenario || disabled || isEmpty(scenario)}
                        disableFuture
                        minDate={startDate || globalMin || null}
                        maxDate={config.time.forecasting ? config.time.forecasting.min : globalMax}
                        value={cutoffDate ? cutoffDate : globalMax}
                        onAccept={newCutoffDate => setCutoffDate(newCutoffDate.startOf("month").startOf("date"))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectField
                        dataCyProp="multiplier_selector"
                        label={config.i18n.report.multiplier.label}
                        disabled={disabled}
                        value={multiplier}
                        possibleValues={Object.keys(multiplierOptions)}
                        metadata={multiplierOptions}
                        onChange={e => setMultiplier(e)}
                        sx={{ flex: 1 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        disabled={disabled}
                        control={(
                            <Checkbox
                                data-cy="hide_empty_rows"
                                checked={hideRows}
                                onChange={event => setHideRows(event.target.checked)}
                            />
                        )}
                        label={config.i18n.selector.hide_rows}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        disabled={disabled}
                        control={(
                            <Checkbox
                                data-cy="hide_initial_months"
                                checked={hideInitialMonths}
                                onChange={event => setHideInitialMonths(event.target.checked)}
                            />
                        )}
                        label={config.i18n.selector.hide_initial_months}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DimensionSelector
                        dataCyProp="filters-in-dim-selector"
                        title={config.i18n.customization_bar.filters_in}
                        disabled={disabled}
                        documentDimensions={documentDimensions}
                        dimensions={UIFiltersInDimensions}
                        dimensionFilters={UIFiltersInParameters}
                        filtersInParameters={UIFiltersInParameters}
                        filtersOutParameters={UIFiltersOutParameters}
                        metadata={reportDimensionsMetadata}
                        parameters={{
                            DATE: [startDate, endDate],
                        }}
                        isFiltersIn={true}
                        onUpdate={(dimension, dimensionFilters = null, metadata = null) => {
                            const newDimensions = union(UIFiltersInDimensions, [dimension]);

                            const newReportDimParameters = { ...UIFiltersInParameters };

                            // remove entry on dimension parameters, if the value is empty.
                            if (dimensionFilters !== null) {
                                Object.entries(dimensionFilters).forEach(([key, value]) => {
                                    if (isEmpty(value)) {
                                        delete newReportDimParameters[key];
                                    } else {
                                        newReportDimParameters[key] = value;
                                    }
                                });
                            }

                            // HACK: when a filter is set, existing filters out of that dimension should be removed
                            if (dimension in config.hierarchy && config.hierarchy[dimension].some(key => key in newReportDimParameters)) {
                                config.hierarchy[dimension].forEach((key) => {
                                    delete UIFiltersOutParameters[key];
                                });
                                onFiltersOutUpdate(UIFiltersOutDimensions, UIFiltersOutParameters, metadata);
                            } else if (dimension in newReportDimParameters) {
                                delete UIFiltersOutParameters[dimension];
                                onFiltersOutUpdate(UIFiltersOutDimensions, UIFiltersOutParameters, metadata);
                            }

                            onFiltersInUpdate(newDimensions, newReportDimParameters, metadata);
                        }}
                        onDelete={(dimension, dimensionKeys) => {
                            const newDimensions = without(UIFiltersInDimensions, dimension);
                            const newDimensionFilters = omit(UIFiltersInParameters, [dimension, ...dimensionKeys]);

                            onFiltersInUpdate(newDimensions, newDimensionFilters);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DimensionSelector
                        dataCyProp="filters-out-dim-selector"
                        title={config.i18n.customization_bar.filters_out}
                        disabled={disabled}
                        documentDimensions={documentDimensions}
                        dimensions={UIFiltersOutDimensions}
                        dimensionFilters={UIFiltersOutParameters}
                        filtersInParameters={UIFiltersInParameters}
                        filtersOutParameters={UIFiltersOutParameters}
                        metadata={reportDimensionsMetadata}
                        parameters={{
                            DATE: [startDate, endDate],
                        }}
                        isFiltersIn={false}
                        onUpdate={(dimension, dimensionFilters = null, metadata = null) => {
                            const newDimensions = union(UIFiltersOutDimensions, [dimension]);

                            const newReportDimParameters = { ...UIFiltersOutParameters };

                            // remove entry on dimension parameters, if the value is empty.
                            if (dimensionFilters !== null) {
                                Object.entries(dimensionFilters).forEach(([key, value]) => {
                                    if (isEmpty(value)) {
                                        delete newReportDimParameters[key];
                                    } else {
                                        newReportDimParameters[key] = value;
                                    }
                                });
                            }

                            onFiltersOutUpdate(newDimensions, newReportDimParameters, metadata);
                        }}
                        onDelete={(dimension, dimensionKeys) => {
                            const newDimensions = without(UIFiltersOutDimensions, dimension);
                            const newDimensionFilters = omit(UIFiltersOutParameters, [dimension, ...dimensionKeys]);

                            onFiltersOutUpdate(newDimensions, newDimensionFilters);
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

ReportCustomizationBar.propTypes = {
    startDate: PropTypes.object,
    setStartDate: PropTypes.func,
    endDate: PropTypes.object,
    setEndDate: PropTypes.func,
    globalMin: PropTypes.object,
    globalMax: PropTypes.object,
    maxDate: PropTypes.object,
    setMaxDate: PropTypes.func,
    scenario: PropTypes.object,
    setScenario: PropTypes.func,
    scenarioOptions: PropTypes.array,
    multiplier: PropTypes.string,
    setMultiplier: PropTypes.func,
    multiplierOptions: PropTypes.object,
    hideRows: PropTypes.bool,
    setHideRows: PropTypes.func,
    hideInitialMonths: PropTypes.bool,
    setHideInitialMonths: PropTypes.func,
    documentDimensions: PropTypes.object,
    UIFiltersInDimensions: PropTypes.array,
    UIFiltersInParameters: PropTypes.object,
    UIFiltersOutDimensions: PropTypes.array,
    UIFiltersOutParameters: PropTypes.object,
    reportDimensionsMetadata: PropTypes.object,
    onFiltersInUpdate: PropTypes.func,
    onFiltersOutUpdate: PropTypes.func,
    cutoffDate: PropTypes.object,
    setCutoffDate: PropTypes.func,
    disabled: PropTypes.bool,
};

export default ReportCustomizationBar;
