import React, { useState } from "react";
import PropTypes from "prop-types";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ClickableDatePicker = ({ views, format, label, disabled, minDate, maxDate, value, onAccept, dataCyProp, required, error, helperText, fullWidth }) => {
    const [open, setOpen] = useState(false);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                open={open}
                onOpen={() => !disabled && setOpen(true)}
                onClose={() => setOpen(false)}
                slotProps={{
                    textField: {
                        "size": "small",
                        "fullWidth": fullWidth,
                        "onClick": () => !disabled && setOpen(true),
                        "readOnly": true,
                        "sx": {
                            "& .MuiInputBase-input": {
                                cursor: "pointer",
                            },
                        },
                        "data-cy": dataCyProp,
                        "required": required,
                        "error": error,
                        "helperText": helperText,
                    },
                }}
                views={views}
                format={format}
                label={label}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                onAccept={onAccept}
                timezone="UTC"
            />
        </LocalizationProvider>
    );
};

ClickableDatePicker.propTypes = {
    views: PropTypes.array,
    format: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    value: PropTypes.any,
    onAccept: PropTypes.func,
    dataCyProp: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    fullWidth: PropTypes.bool,
};

ClickableDatePicker.defaultProps = {
    fullWidth: true,
};

export default ClickableDatePicker;
