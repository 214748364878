"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectorOperator = void 0;
/**
 * An operator to be applied to the values of calculated collector entry items
 */
var CollectorOperator;
(function (CollectorOperator) {
    CollectorOperator["SUM"] = "SUM";
    CollectorOperator["DELTA"] = "DELTA";
    CollectorOperator["RATIO"] = "RATIO";
    CollectorOperator["PRODUCT"] = "PRODUCT";
    CollectorOperator["AVERAGE"] = "AVERAGE";
    CollectorOperator["ONE_MINUS"] = "ONE_MINUS";
    CollectorOperator["PRODUCT_1000"] = "PRODUCT_1000";
    CollectorOperator["PRODUCT_MILLION"] = "PRODUCT_MILLION";
    CollectorOperator["RATIO_1000"] = "RATIO_1000";
    CollectorOperator["RATIO_MILLION"] = "RATIO_MILLION";
    CollectorOperator["INVERT"] = "INVERT";
    CollectorOperator["ONE"] = "ONE";
    CollectorOperator["PRODUCT_NAOH"] = "PRODUCT_NAOH";
    CollectorOperator["PRODUCT_NA2CO3"] = "PRODUCT_NA2CO3";
    CollectorOperator["PRODUCT_NACLO3"] = "PRODUCT_NACLO3";
    CollectorOperator["PRODUCT_H2O2"] = "PRODUCT_H2O2";
    CollectorOperator["PRODUCT_O2"] = "PRODUCT_O2";
})(CollectorOperator || (exports.CollectorOperator = CollectorOperator = {}));
