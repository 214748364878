import { defaultLabelFontSize, defaultValueLabelSetting, displayStatLines, getAxisTooltip, getAxisV, getBase, getFormattedValue, getYSeries } from "./common";
import { getAxisV as getTimeAxisV, getAxisTooltip as getTimeAxisTooltip, getForecastingLine, getYSeries as getTimeYSeries } from "./timeline/common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    let chartOptions = {
        ...getBase(title, baseFontSize),
        labelLayout: { hideOverlap: true },
    };

    // Column chart can have both time axis and categorical axis
    if (data.label_type.x === "DATE") {
        chartOptions = {
            ...chartOptions,
            ...getTimeAxisTooltip(data, config, baseFontSize),
            ...getTimeAxisV(data, config, baseFontSize),
            ...getTimeYSeries(data, "bar", baseFontSize, config, false, {
                label: defaultValueLabelSetting("top", baseFontSize, config,
                    // value[0] is x-value (the timestamp), value[1] the y-value
                    value => getFormattedValue(value.data.value[1], "y", data, config)),
                ...getForecastingLine(data, config, baseFontSize), // time results may have forecasting line
            }),
        };

        // Min & Max labels are not formatted to the appropriate time format, so they are not shown
        chartOptions.xAxis.axisLabel.showMaxLabel = false;
        chartOptions.xAxis.axisLabel.showMinLabel = false;
    } else {
        chartOptions = {
            ...chartOptions,
            ...getAxisV(data, config, baseFontSize),
            ...getAxisTooltip(data, config, baseFontSize),
            ...getYSeries(data, "bar", baseFontSize, config, false, {
                label: defaultValueLabelSetting("top", baseFontSize, config, value => getFormattedValue(value.data.value, "y", data, config)),
                markLine: { // Categorical results may have statistical lines
                    data: statisticalLines && data.variables ? displayStatLines(statisticalLines, data.variables, config) : [],
                    symbol: "none",
                    lineStyle: {
                    // this style will be applied to all lines
                        width: 2,
                    },
                    label: {
                        fontSize: defaultLabelFontSize(baseFontSize),
                        formatter: function (params) {
                            return params.name + " : " + getFormattedValue(params.data.yAxis, "y", data, config);
                        },
                        position: "insideEndTop",
                    },
                    animation: false,
                    silent: true,
                },
            }),
        };
    }
    return chartOptions;
};
