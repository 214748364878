import { getAxisTooltip, getAxisV, getForecastingLine, getYSeries } from "./common";
import { defaultValueLabelSetting, getBase, getFormattedValue, getLegend } from "../common";
import { getChartOptions as getAreaChartOptions } from "../stackedArea";
import { isEqual } from "lodash";

export function getChartOptions(title, statisticalLines, data, config, baseFontSize) {
    let chartOptions;

    // If x type is not date, then we return an area chart, instead of stacked timeline.
    if (data.label_type.x !== "DATE") {
        chartOptions = getAreaChartOptions(title, statisticalLines, data, config, baseFontSize);
    } else {
        chartOptions = {
            ...getBase(title, baseFontSize),
            ...getAxisV(data, config, baseFontSize),
            ...getAxisTooltip(data, config, baseFontSize),
            ...getLegend(data, config, baseFontSize),
            ...getYSeries(data, "line", baseFontSize, config, false, {
                areaStyle: {},
                triggerLineEvent: true,
                ...getForecastingLine(data, config, baseFontSize),
            }),
        };
    }

    // Add total label at the top of the stacked chart
    chartOptions.series.map((seriesEntry, index) => {
        if (index === chartOptions.series.length - 1) {
            seriesEntry.label = defaultValueLabelSetting("top", baseFontSize, config, (entry) => {
                const id = entry.data.name;
                const sum = data.label_type.x !== "DATE"
                    ? data.result.find(r => isEqual(r.label, id)).y.reduce((a, b) => a + b, 0)
                    : data.result.find(r => r.label.isSame(id)).y.reduce((a, b) => a + b, 0);

                return getFormattedValue(sum, "y", data, config);
            });
        }
        return seriesEntry;
    });

    return chartOptions;
}
