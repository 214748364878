"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextStyle = void 0;
var TextStyle;
(function (TextStyle) {
    TextStyle["NORMAL"] = "normal";
    TextStyle["BOLD"] = "bold";
    TextStyle["ITALIC"] = "italic";
    TextStyle["HEADER"] = "header";
})(TextStyle || (exports.TextStyle = TextStyle = {}));
