import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReportDetails from "./ReportDetails";
import SaveIcon from "@mui/icons-material/Save";
import { AppContext } from "../../AppRouter";
import { isForecastingEnabled } from "../../util/feature_flag_util";
import { cloneReport as cloneReportApi } from "../../util/client";

const CloneReport = () => {
    const { client, config, notify } = useContext(AppContext);

    const [shouldValidate, setShouldValidate] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { reportId } = useParams();

    const isValid = (name, template, startDate, endDate) => {
        return !!(name && template && startDate && endDate);
    };

    const cloneReport = (name, template, scenario, startDate, endDate, cutoffDate, multiplier, hideEmptyRows, hideInitialMonths,
        filtersInDimensions, filtersInParameters, filtersOutDimensions, filtersOutParameters) => {
        setShouldValidate(true);
        if (reportId && isValid(name, template, startDate, endDate)) {
            setLoading(true);
            cloneReportApi(client, reportId, name, template.id, startDate, endDate, scenario?.id, isForecastingEnabled(config) ? cutoffDate : null, multiplier, hideEmptyRows, hideInitialMonths, filtersInDimensions, filtersInParameters, filtersOutDimensions, filtersOutParameters)
                .then(() => {
                    setLoading(false);
                    navigate("/report");
                })
                .catch((error) => {
                    setLoading(false);
                    notify.error(error, "report.clone");
                });
        }
    };

    return (
        <ReportDetails
            operation="clone"
            title={config.i18n.report.clone}
            button={config.i18n.button.save}
            icon={<SaveIcon />}
            shouldValidate={shouldValidate}
            loading={loading}
            fetchReport
            onClick={cloneReport}
        />
    );
};

export default CloneReport;
