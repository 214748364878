"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var ErrorService = /** @class */ (function () {
    function ErrorService() {
    }
    /**
     * Get Profile
     * @returns any Successful Response
     * @throws ApiError
     */
    ErrorService.errorGetProfile = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/sentry/',
        });
    };
    return ErrorService;
}());
exports.ErrorService = ErrorService;
