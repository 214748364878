import { last } from "lodash";

export const computeOperator = (values, operator) => {
    if ((values.every(el => el == null || !isDecimalNumber(el)) && operator !== "ONE") || (values.some(el => el == null) && (operator === "PRODUCT")) || (last(values) == null && operator === "RATIO")) {
        return null;
    }

    switch (operator) {
        case "SUM":
            return values.reduce((a, b) => (Number(a) || 0) + (Number(b) || 0));
        case "PRODUCT":
            return values.reduce((a, b) => (Number(a) || 0) * (Number(b) || 0));
        case "DELTA":
            return values.reduce((a, b) => (Number(a) || 0) - (Number(b) || 0));
        case "AVERAGE":
            return values.reduce((a, b) => (Number(a) || 0) + (Number(b) || 0)) / values.length;
        case "RATIO":
            return values.reduce((a, b) => (Number(a) || 0) / Number(b));
        case "ONE_MINUS":
            return Number(1) - values.reduce(a => (Number(a) || 0));
        case "PRODUCT_1000":
            return values.reduce(a => (Number(a) || 0)) * Number(1000);
        case "PRODUCT_MILLION":
            return values.reduce(a => (Number(a) || 0)) * Number(1000000);
        case "RATIO_1000":
            return values.reduce(a => (Number(a) || 0)) / Number(1000);
        case "RATIO_MILLION":
            return values.reduce(a => (Number(a) || 0)) / Number(1000000);
        case "INVERT":
            return -values.reduce(a => (Number(a) || 0));
        case "ONE":
            return Number(1);
        case "PRODUCT_NAOH":
            return values.reduce(a => (Number(a) || 0)) * Number(0.8875);
        case "PRODUCT_NA2CO3":
            return values.reduce(a => (Number(a) || 0)) * Number(1.33962);
        case "PRODUCT_NACLO3":
            return values.reduce(a => (Number(a) || 0)) * Number(1.667);
        case "PRODUCT_H2O2":
            return values.reduce(a => (Number(a) || 0)) * Number(1.044);
        case "PRODUCT_O2":
            return values.reduce(a => (Number(a) || 0)) * Number(4.44);
    }
};

export const isDecimalNumber = (value) => {
    return /^-?\d+(\.\d+)?$/.test(value);
};

export function toPrecisionWithTrailingZeros(num, significantDigits) {
    if (num < 1) {
        return num.toFixed(significantDigits - 1);
    }

    const numWithPrecision = num.toPrecision(significantDigits);

    if (numWithPrecision.includes("e")) {
        const [coefficient, exponent] = numWithPrecision.split("e");
        const exp = parseInt(exponent);
        const fixedCoefficient = parseFloat(coefficient).toFixed(significantDigits);

        if (exp >= 0) {
            return (parseFloat(fixedCoefficient) * Math.pow(10, exp)).toFixed(0);
        } else {
            return (parseFloat(fixedCoefficient) * Math.pow(10, exp)).toFixed(Math.abs(exp) + 3);
        }
    } else {
        return numWithPrecision;
    }
}
