import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import SaveIcon from "@mui/icons-material/Save";
import { AppContext } from "../../AppRouter";
import CollectorDetails from "./CollectorDetails";
import { createCollector as createCollectorApi } from "../../util/client";

const CreateCollector = () => {
    const { client, config, notify } = useContext(AppContext);

    const [shouldValidate, setShouldValidate] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    function isValid(name) {
        return !isEmpty(name);
    }

    const createCollector = (name, template, startDate, endDate, cutoffDate) => {
        setShouldValidate(true);
        if (isValid(name)) {
            setLoading(true);
            createCollectorApi(client, name, template, startDate, endDate, cutoffDate)
                .then(() => {
                    setLoading(false);
                    navigate("/collector");
                })
                .catch((error) => {
                    setLoading(false);
                    notify.error(error, "collector.create");
                });
        }
    };

    return (
        <CollectorDetails
            operation="create"
            title={config.i18n.collector.create.title}
            button={config.i18n.button.save}
            icon={<SaveIcon />}
            shouldValidate={shouldValidate}
            loading={loading}
            hasTemplate
            onClick={createCollector}
        />
    );
};

export default CreateCollector;
