import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import Markdown from "markdown-to-jsx";
import { AppContext } from "../../AppRouter";
import ChipList from "../ChipList";

const MultiSelectField = ({ size, value, label, disabled, possibleValues, metadata, onChange, sx, dataCyProp, renderValue, useMarkdown, required, error, helperText }) => {
    const { config } = useContext(AppContext);

    return (
        <FormControl sx={sx} size={size} fullWidth error={error} required={required}>
            <InputLabel id="value-select-label" disabled={disabled}>{config.i18n.custom_parameters[label] || label}</InputLabel>
                <Select
                    multiple
                    data-cy={dataCyProp}
                    labelId="value-multi-select-label"
                    id="value-multi-select"
                    value={value}
                    disabled={disabled}
                    onChange={event => onChange(event.target.value)}
                    label={config.i18n.custom_parameters[label] || label}
                    renderValue={renderValue ? renderValue : selected => <ChipList selected={selected} />}
                    error={error}
                >
                    {
                        possibleValues?.map((option) => {
                            const optionKey = option?.id || option?.name || option;
                            const optionValue = config.i18n.Dimension[option] || metadata[option] || option?.name || option;

                            return (
                                <MenuItem
                                    data-cy={"select-option-" + optionKey}
                                    key={optionKey}
                                    value={option}
                                    disabled={disabled}
                                >
                                    {useMarkdown ? <Markdown>{optionValue}</Markdown> : optionValue}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

MultiSelectField.propTypes = {
    value: PropTypes.any,
    size: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    possibleValues: PropTypes.array,
    metadata: PropTypes.object,
    onChange: PropTypes.func,
    sx: PropTypes.object,
    dataCyProp: PropTypes.string,
    renderValue: PropTypes.any,
    multiple: PropTypes.bool,
    useMarkdown: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
};

MultiSelectField.defaultProps = {
    size: "small",
    metadata: {},
    onChange: () => {
    },
    sx: {},
    dataCyProp: "selector",
    multiple: false,
    useMarkdown: false,
};

export default MultiSelectField;
