import React from "react";
import PropTypes from "prop-types";
import { useContext } from "react";

import AutoCompleteBox from "./AutoCompleteBox";
import { Stack } from "@mui/system";
import { AppContext } from "../../AppRouter";

const HierarchyAutoCompleteBox = ({ dimensions, onChange, onQuery, values, disabled, fullWidth, metadata, sx }) => {
    const { config } = useContext(AppContext);

    return (
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
            {
                dimensions.map((dim, i) => {
                    return (
                        <AutoCompleteBox
                            multiple
                            disableCloseOnSelect
                            disabled={disabled}
                            dimension={dim}
                            label={config.i18n.Dimension[dim] || dim}
                            onChange={(vals) => {
                                let newMetadata = { ...metadata };
                                newMetadata = vals.reduce((acc, value) => {
                                    return { ...acc, [value.id]: value };
                                }, newMetadata);

                                const newValues = [...values];
                                // FIXME #1028: Standardize API.
                                newValues[i] = vals.map(val => val?.id || val);
                                for (let j = i + 1; j < dimensions.length; j++) {
                                    newValues[j] = [];
                                }

                                onChange(dimensions, newValues, newMetadata);
                            }}
                            value={values[i]}
                            metadata={metadata}
                            onQuery={(queryString, page) => {
                                const dimsToIgnore = dimensions.filter((dim, idx) => idx >= i && dim);
                                return onQuery(dim, queryString, dimsToIgnore, page);
                            }}
                            key={dim + "-autocompletebox"}
                            fullWidth={fullWidth}
                            sx={sx}
                        />
                    );
                })
            }
        </Stack>
    );
};

HierarchyAutoCompleteBox.propTypes = {
    dimensions: PropTypes.object,
    onChange: PropTypes.func,
    onQuery: PropTypes.func,
    values: PropTypes.array,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    metadata: PropTypes.object,
    sx: PropTypes.object,
};

export default HierarchyAutoCompleteBox;
