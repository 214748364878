export const hasValue = (val) => {
    return !(val === null || val === undefined || val === "");
};

export const roundToDecimalCase = (num) => {
    return Math.round((num + Number.EPSILON) * 10) / 10;
};

export const compareSet = (set1, set2) => set1.size === set2.size && [...set1].every(e => set2.has(e));

/**
 * Try to get the hierarchical dimension, a given dimension belongs to.
 * If unable, returns the given dimension.
 *
 * @param {object} config the Advisor configuration.
 * @param {string} dimension the dimension to try to get the corresponding hierarchical dimension.
 */
export function tryGetHierarchicalDimension(config, dimension) {
    for (const hierarchicalDim of Object.keys(config.hierarchy)) {
        const doesDimBelongToHierarchy = config.hierarchy[hierarchicalDim].some(s => s === dimension);

        if (doesDimBelongToHierarchy) {
            return hierarchicalDim;
        }
    }

    return dimension;
}
