import { defaultValueLabelSetting, getAxisH, getAxisTooltip, getBase, getFormattedValue, getLegend, getYSeries } from "./common";
import { isEqual } from "lodash";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    let chartOptions = {
        ...getBase(title, baseFontSize),
        ...getAxisH(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        ...getLegend(),
        ...getYSeries(data, "bar", baseFontSize, config, false, {}),
    };

    // Add total label at the top of the columns
    // We need a new series in the chart that doesn't change the chart itself (hence the zero value) but it allows us to show an additional label.
    const sumSeries = data.result.map((dateEntry) => {
        return {
            id: dateEntry.x,
            name: dateEntry.x,
            value: data.label_type.x === "DATE" ? [dateEntry.x, 0] : 0, // Charts with dates as X values need to be formatted differently
        };
    });

    chartOptions.series.push(
        {
            ...chartOptions.series[0],
            data: sumSeries,
            label: {
                ...defaultValueLabelSetting("right", baseFontSize, config, (entry) => {
                    const id = entry.data.name;
                    const sum = data.label_type.x === "DATE"
                        ? data.result.find(r => r.label.isSame(id)).y.reduce((a, b) => a + b, 0)
                        : data.result.find(r => isEqual(r.label, id)).y.reduce((a, b) => a + b, 0);

                    return getFormattedValue(sum, "y", data, config);
                }),
            },
        },
    );

    return chartOptions;
};
