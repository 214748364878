import dayjs from "dayjs";

export function getQuarter(dateValue) {
    const date = dayjs.utc(dateValue);
    return Math.floor(date.month() / 3) + 1;
}

export function getAllMonthsBetweenDates(startDate, endDate) {
    const dates = [];
    let currentDate = dayjs.utc(startDate);

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
        dates.push(currentDate);
        currentDate = currentDate.add(1, "month");
    }
    return dates;
}

export function convertToISODate(date) {
    return date.format("YYYY-MM-DD");
}
