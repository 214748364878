import { union } from "lodash";

export function mergeFilters(uiFilters, globalFilters) {
    const mergedFilters = {};

    Object.keys(uiFilters).forEach((dimension) => {
        if (dimension in globalFilters) {
            mergedFilters[dimension] = union(uiFilters[dimension], globalFilters[dimension]);
        } else {
            mergedFilters[dimension] = uiFilters[dimension];
        }
    });

    return {
        ...globalFilters,
        ...mergedFilters,
    };
}
